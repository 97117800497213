const conversationRewards = {
  0: "1",
  200: "2",
  500: "3",
  1000: "4",
  2000: "5",
  5000: "6",
  10000: "7",
  20000: "8",
  50000: "9",
  100000: "10",
  200000: "11",
  500000: "12",
  1000000: "13",
  2000000: "14",
  5000000: "15",
  1000000: "16",
  2000000: "17",
  5000000: "18",
  10000000: "19",
  9007199254740991: "20",
}

export const abbreviateNumber = (value) => {
    var newValue = value;
    if (value >= 1000) {
        var suffixes = ["", "k", "m", "b","t"];
        var suffixNum = Math.floor( (""+value).length/3 );
        var shortValue = '';
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
            var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
        newValue = shortValue+suffixes[suffixNum];
    }
    return newValue;
}

export const numberWithCommas = (x) => {
    if (x == null) {
        return null;
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getProgressFromMessages = (botMessages, botConversations) => {
    const conversationRewardThresholds = Object.keys(conversationRewards).map(
        Number
    )
    const nextThreshold = conversationRewardThresholds.find(
        threshold => threshold > botMessages
    )
    // Find last instance where threshold is <= botMessages
    const currentThreshold = conversationRewardThresholds
        .slice()
        .reverse()
        .find(threshold => threshold <= botMessages)

    return {
        currentLevel: conversationRewards[currentThreshold],
        progressPercentage: (
            (botMessages / nextThreshold) * 100
        ).toFixed(1),
        totalMessages: numberWithCommas(botMessages),
        totalConversations: numberWithCommas(botConversations)
    }
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export const dateDiffInDays = (a, b) => {
    return Math.abs(Math.floor((a - b) / _MS_PER_DAY));
}