import React, { Component } from "react";
import { Line } from "react-chartjs-2";

const TotalUsage = (props) => {
  const data = {
    labels: props.labels,
    datasets: [
      {
        label: "Total Usage",
        data: props.data,
        borderColor: "rgba(213, 51, 105, 1)",
        borderWidth: "8",
        backgroundColor: "rgba(213, 51, 105, 0.1)",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    legend: false,

    scales: {
      yAxes: [
        {
          gridLines: {
            color: "rgba(89, 59, 219,0.1)",
            drawBorder: true,
          },
          ticks: {
            fontColor: "#999",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            zeroLineColor: "transparent",
          },
          ticks: {
            stepSize: 5,
            fontColor: "#999",
            fontFamily: "Nunito, sans-serif",
          },
        },
      ],
    },
    tooltips: {
      enabled: false,
      mode: "index",
      intersect: false,
      titleFontColor: "#888",
      bodyFontColor: "#555",
      titleFontSize: 12,
      bodyFontSize: 15,
      backgroundColor: "rgba(256,256,256,0.95)",
      displayColors: true,
      xPadding: 10,
      yPadding: 7,
      borderColor: "rgba(220, 220, 220, 0.9)",
      borderWidth: 2,
      caretSize: 6,
      caretPadding: 10,
    },
  };

  return (
    <div style={{ height: "300px" }}>
      <Line data={data} options={options} height={300} />
    </div>
  );
}

export default TotalUsage;
