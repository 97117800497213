import React, { useState } from "react";

import FirebaseContext from "../../contexts/firebase/context"

import Developers from "./Developers"
import RewardProgress from "./RewardProgress"
import TopIndicator from "./TopIndicator"
import Breakdown from "./Breakdown"
import Usage from "./Usage"

const Home = () => {
  const [refreshToggle, setRefreshToggle] = useState(false);
  const { bots, getAllBots, user } = React.useContext(FirebaseContext)

  React.useEffect(() => {
    if (bots === null) {
      getAllBots()
    }
  }, [bots, getAllBots])

  return (
    <div className="row">
      <div className="col-xl-9 col-xxl-8">
        <div className="row">
          <TopIndicator />
          <RewardProgress />
          <Usage />
        </div>
      </div>
      <div className="col-xl-3 col-xxl-4">
        <div className="row">
          <div className="col-xl-12 col-md-6">
            <Breakdown />
          </div>
          <div className="col-xl-12 col-md-6">
            <Developers />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
