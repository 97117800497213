import * as React from "react"
import firebase from "firebase/app"

import "firebase/auth"
import "firebase/database"

import FirebaseContext from "./context"
import { getFirebaseApp } from "./instance"
import { createUserIfNotExist, getAllBots, getGlobalStats } from "./database"

export const FirebaseProvider = ({ children }) => {
  const [firebaseApp, setFirebaseApp] = React.useState(null)
  const [user, setUser] = React.useState(null)
  const [initialized, setInitialized] = React.useState(false)
  const [signInWithGoogle, setSignInWithGoogle] = React.useState(null)
  const [signOut, setSignOut] = React.useState(null)
  const [bots, setBots] = React.useState(null)
  const [globalStats, setGlobalStats] = React.useState(null)

  React.useEffect(() => {
    // Initialize app.
    const _firebaseApp = getFirebaseApp()
    console.log("Initialized Firebase App:")
    console.log(_firebaseApp)
    setFirebaseApp(_firebaseApp)

    // Subscribe to user state change.
    firebase.auth().onAuthStateChanged(_user => {
      console.log("Setting Firebase User:")
      console.log(_user)
      setUser(_user)
      setInitialized(true)
    })

    const _signInWithGoogle = (history) => {
      const provider = new firebase.auth.GoogleAuthProvider()

      _firebaseApp
        .auth()
        .signInWithPopup(provider)
        .then(result => {
          console.log("Signed in with Google.")

          /** @type {firebase.auth.OAuthCredential} */
          const credential = result.credential

          // This gives you a Google Access Token. You can use it to access the Google API.
          const token = credential.accessToken
          console.log(`Credential token: ${token}`)
          // The signed-in user info.
          const _user = result.user

          console.log(`User:`)
          console.log(_user)
          createUserIfNotExist(_user, _firebaseApp)

          history.push('/dashboard');
        })
        .catch(error => {
          console.log("Error signing in with Google: ")
          console.log(error)
          // // Handle Errors here.
          // var errorCode = error.code
          // var errorMessage = error.message
          // // The email of the user's account used.
          // var email = error.email
          // // The firebase.auth.AuthCredential type that was used.
          // var credential = error.credential
        })
    }
    setSignInWithGoogle(() => _signInWithGoogle)

    const _signOut = (history) => {
      _firebaseApp
        .auth()
        .signOut()
        .then(result => {
          console.log("Signed out.")
          history.push('/login');
        })
        .catch(error => {
          console.log("Error signing out: ")
          console.log(error)
        })
    }
    setSignOut(() => _signOut)
  }, [])

  const getAllBotsCallback = React.useCallback(
    () =>
      getAllBots(firebaseApp).then(_bots => {
        _bots = _bots
          .sort((botA, botB) => botB.score - botA.score)
          .map((bot, i) => ({ ...bot, leaderboardPosition: i + 1 })) // Add leaderboard position
        setBots(_bots)
      }),
    [firebaseApp]
  )
  const getGlobalStatsCallback = React.useCallback(
    () => getGlobalStats(firebaseApp).then(_stats => setGlobalStats(_stats)),
    [firebaseApp]
  )
  return (
    <FirebaseContext.Provider
      value={{
        firebaseApp,
        user,
        initialized,
        signInWithGoogle,
        signOut,
        bots,
        getAllBots: getAllBotsCallback,

        globalStats,
        getGlobalStats: getGlobalStatsCallback,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  )
}
