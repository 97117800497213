import React from "react";
import Increase from "./acara/dashboardChart/Increase";

import FirebaseContext from "../../contexts/firebase/context"

const getTopPercent = (bots, user) => {
  const uidToScore = {}

  bots.forEach(bot => {
    const botScore = bot.stats.messagesSent
    const developerUid = bot.developerUid
    if (botScore > 0) {
    	if (developerUid in uidToScore) {
	      uidToScore[developerUid] += botScore
	    } else {
	      uidToScore[developerUid] = botScore
	    }
    }
  })
  
  if (user.uid in uidToScore) {
  	const uidsInIncreasingScore = Object.entries(uidToScore).sort(
	    (a, b) => b[1] - a[1]
	  )
	  const userPlace = uidsInIncreasingScore.findIndex(
	    value => value[0] == user.uid
	  )

    let totalUsers = uidsInIncreasingScore.length;
	  console.log(
      `Ranked ${userPlace} place out of ${totalUsers}.`
    )

	  return { 
	  	topPercent: (userPlace / totalUsers) * 100,
	  	userPlace: userPlace + 1, 
	  	totalUsers: totalUsers
	  };
  } else {
    let totalUsers = Object.keys(uidToScore).length
  	return {
  		topPercent: 100,
  		userPlace: totalUsers,
  		totalUsers: totalUsers
  	};
  }
  
}

const TopIndicator = () => {
	const { bots, getAllBots, user } = React.useContext(FirebaseContext)
  React.useEffect(() => {
    if (bots === null) {
      getAllBots()
    }
  }, [bots, getAllBots])

  const { topPercent, userPlace, totalUsers } = React.useMemo(() => {
    if (bots !== null && user !== null) {
      return getTopPercent(bots, user);
    }
    return {
    	topPercent: 100, 
    	userPlace: 0,
    	totalUsers: 0
    };
  }, [bots, user])

	return (
	  <div className="col-xl-12 col-xxl-6 col-lg-12 col-sm-6">
      <div className='widget-stat card bg-primary'>
        <div className='card-body  p-4'>
          <div className='media'>
            <span className='mr-3'>
              <i className='la la-users'></i>
            </span>
            <div className='media-body text-white'>
              <p className='mb-1'>Ranking</p>
              <h3 className='text-white'>{userPlace} of {totalUsers}</h3>
              <div className='progress mb-2 bg-secondary'>
                <div
                  className='progress-bar progress-animated bg-light'
                  style={{ width: `${100 - topPercent}%` }}
                ></div>
              </div>
              <small>Top {topPercent.toFixed(1)}% among all creators</small>
            </div>
          </div>
        </div>
      </div>
    </div>
	);
}

export default TopIndicator;