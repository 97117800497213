import React,{ useState } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
// image
import logo2 from "../../images/logo-full.png";
import login from "../../images/login-bg.jpg";

import FirebaseContext from "../contexts/firebase/context"

function Login(props) {
	const { signInWithGoogle } = React.useContext(FirebaseContext)

	const [email, setEmail] = useState('demo@demo.com');
    let errorsObj = { email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('123456');

    function onLogin(e) {
        e.preventDefault();
        signInWithGoogle(props.history);
    }

  return (
		<div className="login-wrapper">
			<div className="login-aside-left">
				<Link to="/" className="login-logo">
					<img src={logo2} alt="" />
				  </Link>
				<div className="login-description">
					<h2 className="text-white mb-4">Chai - Chat with AI</h2>
					<p className="fs-12">From your morning cup of tea to brushing your teeth at night, Chai has the conversations to make your day. Whether you’re a chatterbox, a tech fanatic or simply looking for a laugh, there’s something for everyone. Enjoy a never-ending stream of AIs you’ll love to chat with. Join our leading teams building the next wave of world-changing chatbots.</p>
					<ul className="social-icons mt-4">
						<li><Link to={{ pathname: "https://www.reddit.com/r/ChaiApp/" }} target="_blank"><i className="fa fa-reddit"></i></Link></li>
						<li><Link to={{ pathname: "https://github.com/chai-nexus/chai_py" }} target="_blank"><i className="fa fa-github"></i></Link></li>
						<li><Link to={{ pathname: "https://www.linkedin.com/company/seamless-chai/" }} target="_blank"><i className="fa fa-linkedin"></i></Link></li>
					</ul>
					<div className="mt-5">
						<Link to={{ pathname: "https://chai.ml/privacy" }} target="_blank" className="text-white mr-4">Privacy Policy</Link>
						<Link to={{ pathname: "https://chat.whatsapp.com/Btbz9tYOpyG72oqI4NfGE1" }} target="_blank" className="text-white mr-4">Contact</Link>
						<Link to={{ pathname: "https://chai.ml" }} target="_blank" className="text-white">© 2021 Chai</Link>
					</div>
				</div>
			</div>
			<div className="login-aside-right">
				<div className="row m-0 justify-content-center h-100 align-items-center">
				  <div className="col-xl-6 col-xxl-8">
					<div className="authincation-content">
					  <div className="row no-gutters">
						<div className="col-xl-12">
						  <div className="auth-form">
							<div className=" mb-3">
							  <h2 className="text-primary">Welcome to Chai</h2>
							</div>
							<h4 className=" mb-4 ">Sign in by entering information below</h4>
                            {props.errorMessage && (
                                <div className='text-danger'>
                                    {props.errorMessage}
                                </div>
                            )}
                            {props.successMessage && (
                                <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                                    {props.successMessage}
                                </div>
                            )}
							
							  <div className="text-center" >
								<button
								  className="btn btn-primary btn-block"
								  onClick={onLogin}
								>
								  Sign In with Google
								</button>
							  </div>
						
							<div className="new-account mt-3">
							  <p className="">
								Unable to sign in?{" "}
								<Link className="text-primary" to={{ pathname: "https://chat.whatsapp.com/Btbz9tYOpyG72oqI4NfGE1" }} target="_blank">
								  Contact us
								</Link>
							  </p>
							</div>
						  </div>
						</div>
					  </div>
					</div>
				  </div>
				</div>
			</div>
		</div>
  );
};

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);
