import React from "react";

const StepTwo = () => {
   return (
      <section>
         <div className="row">
            <div className="col-lg-12 mb-2">
               <div className="form-group">
                  <label className="text-label">Language Model*</label>
                  <select
                     defaultValue={'option'}
                     className='form-control'
                     id='sel1'
                  >
                     <option>GPT-J Vanilla</option>
                     <option>GPT-J Cornell</option>
                     <option>GPT-NeoX</option>
                  </select>
               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group">
                  <label className="text-label">Bot Name*</label>
                  <input
                     type="text"
                     name="firstName"
                     className="form-control"
                     placeholder="AI"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group">
                  <label className="text-label">User Name*</label>
                  <input
                     type="text"
                     name="lastName"
                     className="form-control"
                     placeholder="User"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-12 mb-2">
               <div className="form-group">
                  <label className="text-label">Context*</label>
                  <input
                     type="text"
                     name="phoneNumber"
                     className="form-control"
                     required
                  />
               </div>
            </div>
         </div>
      </section>
   );
};

export default StepTwo;
