import * as React from "react"

const FirebaseContext = React.createContext({
  firebaseApp: null,
  user: null,
  initialized: null,

  signInWithGoogle: null,
  signOut: null,

  bots: null,
  getAllBots: null,

  globalStats: null,
  getGlobalStats: null,
})

export default FirebaseContext
