import React from "react";
import { Link } from "react-router-dom";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import QRCode from "react-qr-code";

import avatar from "../../../../../../images/chai-black.svg";

const TableRows = ({ bots, user }) => {
	return bots.map((bot, i) => {
    return (
      <React.Fragment key={bot.botUid}>
        <tr role="row" className={i % 2 == 0 ? "even" : "odd"}>
		      <td className="sorting_1">#{bot.leaderboardPosition}</td>
		      <td>
			      <OverlayTrigger overlay={
	              <Tooltip id="tooltip-disabled">
	                 <div className="d-flex align-items-center my-2 bg-white p-2">
		                 <QRCode value={"chai://chai.ml/" + bot.botUid} size="128"/>
	                 </div>
	              </Tooltip>
	            }
	          >
	            <Link to={{ pathname: "https://chai.ml/chat/share/" + bot.botUid }} target="_blank">
		             <div className="media d-flex align-items-center">
		                <div className="avatar avatar-xl mr-2">
		                   <div className="">
		                      <img
														className="avatar-sm rounded-circle"
														src={bot.user.imageUrl}
														onError={({ currentTarget }) => {
					                    currentTarget.onerror = null; // prevents looping
					                    currentTarget.src=avatar;
					                  }}
														alt="avatar"
		                      />
		                   </div>
		                </div>
		                <div className="media-body">
		                    <h5 className="mb-0 fs--1 text-nowrap">
		                      {bot.user.name}
		                    </h5>
		                </div>
		             </div>
		          </Link>
            </OverlayTrigger>
		        
		      </td>
		      <td>
		        <Link to="#">
	             <div className="media d-flex align-items-center">
	                <div className="avatar avatar-xl mr-2">
	                   <div className="">
	                      <img
													className="avatar-sm rounded-circle"
													src={bot.developer.imageUrl}
													onError={({ currentTarget }) => {
				                    currentTarget.onerror = null; // prevents looping
				                    currentTarget.src=avatar;
				                  }}
													alt="avatar"
	                      />
	                   </div>
	                </div>
	                <div className="media-body">
	                    <h5 className="mb-0 fs--1 text-nowrap">
	                      {bot.developer.name}
	                    </h5>
	                </div>
	             </div>
	          </Link>
		      </td>
		      <td>{(bot.engagement * 100).toFixed(1)}%</td>
		      <td>{bot.qualityScore * 2}</td>
		      <td>
		        <Link className="btn btn-primary btn-sm light">
		          {bot.score}
		        </Link>
		      </td>
		    </tr>
      </React.Fragment>
    )}
	)

}

export default TableRows;
