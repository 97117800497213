export const reducer = (state, action) => {
  switch (action.type) {
    case "toggle_button":
      return {
        ...state,
        active: !state.active
      }

    default:
      return state
  }
}

export const initialState = {
  imageUrl: "https://lh3.googleusercontent.com/ogw/ADea4I5Mq-f4FJS-PE8KMyt_JRSJIaWDdXTCfzz8JdBg=s83-c-mo"
}