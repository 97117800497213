import React, { useState, useRef, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

import { Link } from "react-router-dom";
// import data from "./tableData.js";

import TableRows from "./TableRows"

const LeaderboardTable = ({ bots, user }) => {
  const [data, setData] = useState(
    document.querySelectorAll("#example2_wrapper tbody tr")
  );
  const sort = 25;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#example2_wrapper tbody tr"));
    // chackboxFun();
  }, [test]);

  
  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };

  const filteredBots = bots
    .filter(bot => bot.developerUid === user?.uid)
    .map((bot, i) => ({ ...bot, leaderboardPosition: bot.leaderboardPosition }))
  
  return (
    <div id="All" classname="tab-pane">
      <div className="table-responsive">
        <div id="example2_wrapper" className="dataTables_wrapper no-footer">
          <table
            id="example2"
            className="table card-table display dataTablesCard dataTable no-footer"
            role="grid"
            aria-describedby="example2_info"
          >
            <thead>
               <tr role="row">
                  <th
                     className="sorting_asc"
                     tabIndex={0}
                     aria-controls="example2"
                     rowSpan={1}
                     colSpan={1}
                     aria-label="Rank: activate to sort column descending"
                     style={{ width: 63 }}
                     aria-sort="ascending"
                  >
                     Rank
                  </th>
                  <th
                     className="sorting"
                     tabIndex={0}
                     aria-controls="example2"
                     rowSpan={1}
                     colSpan={1}
                     aria-label="Name: activate to sort column ascending"
                     style={{ width: 256 }}
                  >
                     Name
                  </th>
                  <th
                     className="sorting"
                     tabIndex={0}
                     aria-controls="example2"
                     rowSpan={1}
                     colSpan={1}
                     aria-label="Developer: activate to sort column ascending"
                     style={{ width: 256 }}
                  >
                     Developer
                  </th>
                  <th
                     className="sorting"
                     tabIndex={0}
                     aria-controls="example1"
                     rowSpan={1}
                     colSpan={1}
                     aria-label="Engagement : activate to sort column ascending"
                     style={{ width: 75 }}
                  >
                     Engagement
                    <OverlayTrigger overlay={
                        <Tooltip id="tooltip-disabled">
                           Engagement = % conversations get one or more replies.
                        </Tooltip>
                      }
                    >
                      <span className="d-inline-block ml-2">
                        <FontAwesomeIcon icon={faQuestionCircle}/>
                      </span>
                    </OverlayTrigger>
                  </th>
                  <th
                     className="sorting"
                     tabIndex={0}
                     aria-controls="example1"
                     rowSpan={1}
                     colSpan={1}
                     aria-label="Quality: activate to sort column ascending"
                     style={{ width: 63 }}
                  >
                     Quality
                     <OverlayTrigger overlay={
                        <Tooltip id="tooltip-disabled">
                           Quality = median conversation length.
                        </Tooltip>
                      }
                    >
                      <span className="d-inline-block ml-2">
                        <FontAwesomeIcon icon={faQuestionCircle}/>
                      </span>
                    </OverlayTrigger>
                  </th>
                  <th
                     className="sorting"
                     tabIndex={0}
                     aria-controls="example1"
                     rowSpan={1}
                     colSpan={1}
                     aria-label="Score: activate to sort column ascending"
                     style={{ width: 80 }}
                  >
                     Score
                     <OverlayTrigger overlay={
                        <Tooltip id="tooltip-disabled">
                           Score = messages sent by users in last 6 hours.
                        </Tooltip>
                      }
                    >
                      <span className="d-inline-block ml-2">
                        <FontAwesomeIcon icon={faQuestionCircle}/>
                      </span>
                    </OverlayTrigger>
                  </th>
               </tr>
            </thead>
            <tbody>
              <TableRows bots={filteredBots} user={user} />
            </tbody>
          </table>

          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              Showing {activePag.current * sort + 1} to{" "}
              {data.length > (activePag.current + 1) * sort
                ? (activePag.current + 1) * sort
                : data.length}{" "}
              of {data.length} entries
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="example2_paginate"
            >
              <Link
                className="paginate_button previous disabled"
                to="/leaderboard"
                onClick={() =>
                  activePag.current > 0 &&
                  onClick(activePag.current - 1)
                }
              >
                Previous
              </Link>
              <span>
                {paggination.map((number, i) => (
                  <Link
                    key={i}
                    to="/leaderboard"
                    className={`paginate_button  ${
                      activePag.current === i ? "current" : ""
                    } `}
                    onClick={() => onClick(i)}
                  >
                    {number}
                  </Link>
                ))}
              </span>

              <Link
                className="paginate_button next"
                to="/leaderboard"
                onClick={() =>
                  activePag.current + 1 < paggination.length &&
                  onClick(activePag.current + 1)
                }
              >
                Next
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardTable;