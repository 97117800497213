import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import React from "react";
import FirebaseContext from "../../contexts/firebase/context"

import { numberWithCommas } from "../utils";

import BotSummaryChart from "./acara/dashboardChart/BotSummaryChart";

const getTopBots = (bots, user) => {
  const nameToScore = {};
  let totalScore = 0;
  bots.forEach(bot => {
    const botScore = bot.stats.messagesSent
    const botName = bot.user.name
    if (bot.developerUid == user.uid) {
    	totalScore += botScore;
    	if (botName in nameToScore) {
	      nameToScore[botName] += botScore
	    } else {
	      nameToScore[botName] = botScore
	    }
    }
  })
  if (Object.keys(nameToScore).length > 0) {
  	const namesInIncreasingScore = Object.entries(nameToScore).sort(
	    (a, b) => a[1] - b[1]
	  )
	  const namesInTop = {};
	  let topTotalScore = 0;
	  namesInIncreasingScore.slice(-3).forEach(a => {
	  	namesInTop[a[0]] = a[1];
	  	topTotalScore += a[1];
	  });

	  if (topTotalScore != totalScore) {
	  	namesInTop['Others'] = totalScore - topTotalScore;
	  }

	  return namesInTop;
  } else {
  	return null;
  }
  
}

const Breakdown = () => {
	const { bots, getAllBots, user } = React.useContext(FirebaseContext)
  React.useEffect(() => {
    if (bots === null) {
      getAllBots()
    }
  }, [bots, getAllBots])

  const topBots = React.useMemo(() => {
    if (bots !== null && user !== null) {
      return getTopBots(bots, user);
    }
    return null;
  }, [bots, user])

  if (user === null) {
    return "Sign in to see the ranking of your chatbots"
  }

  if (topBots == null) {
  	return null;
  }

  const names = Object.keys(topBots);
  const messages = Object.values(topBots);

	return (
	  <div className="card">
	    <div className="card-header border-0 pb-0">
	      <h4 className="fs-20">Most Popular</h4>
	    </div>
	    <div className="card-body">
	      <div id="donutChart">
	        <BotSummaryChart series={messages} labels={names} />
	      </div>
	      <div className="d-flex justify-content-between mt-4">
	        <div className="pr-2">
	          <svg
	            width={20}
	            height={8}
	            viewBox="0 0 20 8"
	            fill="none"
	            xmlns="http://www.w3.org/2000/svg"
	          >
	            <rect width={20} height={8} rx={4} fill="#214BB8" />
	          </svg>
	          <h4 className="fs-18 text-black mb-1 font-w600">
		          {numberWithCommas(messages[0])}
	          </h4>
	          <span className="fs-14">#3</span>
	        </div>
	        {(messages.length < 2) ? null :
		        <div className="pr-2">
		          <svg
		            width={20}
		            height={8}
		            viewBox="0 0 20 8"
		            fill="none"
		            xmlns="http://www.w3.org/2000/svg"
		          >
		            <rect width={20} height={8} rx={4} fill="#d53369" />
		          </svg>
		          <h4 className="fs-18 text-black mb-1 font-w600">
			          {numberWithCommas(messages[1])}
			         </h4>
		          <span className="fs-14">#2</span>
		        </div>
		      }
		      {(messages.length < 3) ? null :
		      	<div className>
		          <svg
		            width={20}
		            height={8}
		            viewBox="0 0 20 8"
		            fill="none"
		            xmlns="http://www.w3.org/2000/svg"
		          >
		            <rect width={20} height={8} rx={4} fill="#45ADDA" />
		          </svg>
		          <h4 className="fs-18 text-black mb-1 font-w600">
			          {numberWithCommas(messages[2])}
			         </h4>
		          <span className="fs-14">#1</span>
		        </div>
		      }
	      </div>
	    </div>
	  </div>
	);
	
}

export default Breakdown;

