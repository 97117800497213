import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";

import FirebaseContext from "../../contexts/firebase/context"

import { getProgressFromMessages, numberWithCommas } from "../utils";
import avatar from "../../../images/chai-black.svg";


const getTopDevelopers = (bots) => {
  const uidToDeveloper = {}
  bots.forEach(bot => {
    const botScore = bot.stats.messagesSent
    const developerUid = bot.developerUid
    if (botScore > 0) {
      if (developerUid in uidToDeveloper) {
        uidToDeveloper[developerUid].score += botScore;
        uidToDeveloper[developerUid].botCount += 1;
      } else {
        bot.developer.score = botScore;
        bot.developer.botCount = 1;
        uidToDeveloper[developerUid] = bot.developer;
      }
    }
  })

  const developersSorted = Object.values(uidToDeveloper).sort(
    (a, b) => b.score - a.score
  )

  return developersSorted.slice(0, 10);
}

const Developers = () => {
  const { bots, getAllBots } = React.useContext(FirebaseContext)
  React.useEffect(() => {
    if (bots === null) {
      getAllBots()
    }
  }, [bots, getAllBots])

  const topDevelopers = React.useMemo(() => {
    if (bots !== null) {
      return getTopDevelopers(bots);
    }
    return [];
  }, [bots])

  return (
    <div className="card">
      <div className="card-header border-0 pb-0">
        <h4 className="fs-20">Top Developers</h4>
      </div>
      <PerfectScrollbar
        className="card-body pb-0 dz-scroll height630 loadmore-content"
        id="latestSalesContent"
      >
        {topDevelopers.map((developer, i) => {
          const progress = getProgressFromMessages(developer.score);
          return (
            <div className="pb-3 mb-3 border-bottom">
              <div className="d-flex align-items-end">
                <img
                  src={developer.imageUrl}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src=avatar;
                  }}
                  alt={"image"}
                  className="avatar-md rounded-circle mr-2"
                />
                <div className="mr-auto">
                  <h4 className="fs-14 mb-0">
                    <Link to="./app-profile" className="text-black">
                      {developer.name}
                    </Link>
                  </h4>
                  <span className="fs-12">
                    Level {progress.currentLevel} ({numberWithCommas(developer.score)})
                  </span>
                </div>
                <span className="badge badge-sm light badge-primary">
                  {developer.botCount} Bots
                </span>
              </div>
            </div>
          )
        })}
      </PerfectScrollbar>
      <div className="card-footer text-center border-0">
        <Link
          className="btn btn-primary btn-sm dz-load-more"
          id="latestSales"
          to="/leaderboard"
        >
          View More
        </Link>
      </div>
    </div>
  )
}

export default Developers;