import * as React from "react";
/// Components
import Index from './jsx/index';
import { connect, useDispatch } from 'react-redux';
import {  Route, Switch, withRouter } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./scss/main.scss";

import FirebaseContext from "./jsx/contexts/firebase/context"


const SignUp = React.lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = React.lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = React.lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
    });
});
function App (props) {
    const { user, initialized } = React.useContext(FirebaseContext);
    const dispatch = useDispatch();

    React.useEffect(() => {
        checkAutoLogin(dispatch, props.history);
    }, [dispatch, props.history]);
    
    let routes = (  
        <Switch>
            <Route path='/login' component={Login} />
            <Route path='/page-register' component={SignUp} />
            <Route path='/page-forgot-password' component={ForgotPassword} />
        </Switch>
    );

    const timer = function() {
        setTimeout(() => {
            if (user != null) {
                props.history.push('/dashboard');
            } else if (!initialized) {
                timer();
            }
        }, 100);
    };
    if (!initialized) timer();

    if (user != null) {
        return (
            <>
                <React.Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>  
                   }
                >
                    <Index />
                </React.Suspense>
            </>
        );
    
    } else {
        return (
            <div className="vh-100">
                <React.Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    {routes}
                </React.Suspense>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App)); 
