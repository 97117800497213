import React from "react"
import { Link } from "react-router-dom";
import swal from "sweetalert";

import FirebaseContext from "../../../contexts/firebase/context"
import { createDeveloperKey, deleteDeveloperKey } from "../../../contexts/firebase/database"


const Credentials = () => {
	const { user, firebaseApp } = React.useContext(FirebaseContext)

  const [keys, setKeys] = React.useState(null)
  React.useEffect(() => {
    const ref = firebaseApp
      .database()
      .ref(`botDevelopment/developers/${user.uid}`)
    const onKeysChange = snapshot => {
      const val = snapshot.val()
      if (val === null) {
        setKeys([])
      } else {
        setKeys(Object.keys(snapshot.val()))
      }
    }
    ref.on("value", onKeysChange)
    return () => {
      ref.off("value", onKeysChange)
    }
  }, [user, firebaseApp])

  const createKey = React.useCallback(() => createDeveloperKey(user), [user])

	return (
		<>
			<div className="profile-credentials">
			  <div className="pt-4 border-bottom-1 pb-3">
			    <h4 className="text-primary mb-4">Developer Unique ID</h4>
			    <div className='input-group mb-3'>
            <div className='input-group-prepend'>
              <button
							  onClick={() => {
							  	navigator.clipboard.writeText(user.uid);
							    swal(
							      "Copied!",
							      "Successfully	copied to clipboard!",
							      "success"
							    );
							  }}
							  className="btn btn-primary btn-sm dz-load-more"
							>
							  Copy
							</button>
            </div>
            <input type="text" className="form-control" value={user.uid} readOnly={true}/>
          </div>
          <p>
	          Set <code>uid</code> to this when uploading and deploying a chatbot.
          </p>
          
			  </div>
			</div>
			<div className="profile-personal-info">
			  <h4 className="text-primary mb-4">
			    Developer Keys
			  </h4>
			  {keys !== null ? (
	        keys.length === 0 ? (
	          <p className="text-primary">
	            No keys found. Generate a developer key using the buttons below.
	          </p>
	        ) : (
				    <>
			        {keys.map(key => (
		            <div className='input-group mb-3'>
		              <div className='input-group-prepend'>
		                <button
										  onClick={() => {
										  	navigator.clipboard.writeText(key);
										    swal(
										      "Copied!",
										      "Successfully	copied to clipboard!",
										      "success"
										    );
										  }}
										  className="btn btn-primary btn-sm dz-load-more"
										>
										  Copy
										</button>
		                <button
											onClick={() =>
												swal({
													title: "Are you sure?",
													text:
														"Once deleted, you will not be able to recover this key!",
													icon: "warning",
													buttons: true,
													dangerMode: true,
												}).then((willDelete) => {
													if (willDelete) {
														deleteDeveloperKey(user, key);
														swal(
															"Poof! Your key has been deleted!",
															{
																icon: "success",
															}
														);
													} else {
														swal("Your key is safe!");
													}
												})
											}
											className="btn btn-primary btn-sm dz-load-more"
										>
											Delete
										</button>
		              </div>
		              <input type="text" className="form-control" value={key} readOnly={true}/>
		            </div>
		          ))}
		        </>
          )
	      ) : (
	        <div className="p-4 text-sm">Loading keys...</div>
	      )}
	      <p>
		      Set <code>key</code> to this when uploading and deploying a chatbot.
		    </p>
		    <button
				  onClick={() => {
				  	createKey();
				    swal(
				      "Success!",
				      "Created new developer key!",
				      "success"
				    );
				  }}
				  className="btn btn-primary"
				>
				  Create Key
				</button>
			</div>
		</>

	)
}

export default Credentials;