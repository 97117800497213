import firebase from "firebase/app"
import { checkIsClient } from "../utils"

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
}
let firebaseApp = firebase.apps.length > 0 ? firebase.apps[0] : null

export function getFirebaseApp() {
  if (checkIsClient()) {
    if (firebaseApp) return firebaseApp
    console.log("Initializing Firebase App with config: ")
    console.log(config)
    firebaseApp = firebase.initializeApp(config)
    return firebaseApp
  }
  return null
}
