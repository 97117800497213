import React, { useState } from "react";
import { Dropdown, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
// Table
import AllBotTable from "./acara/dashboardTable/Leaderboard/AllBotTable";
import OwnBotTable from "./acara/dashboardTable/Leaderboard/OwnBotTable";

import FirebaseContext from "../../contexts/firebase/context"

const Event = () => {
  const [refreshToggle, setRefreshToggle] = useState(false);

  const { bots, getAllBots, user } = React.useContext(FirebaseContext)
  React.useEffect(() => {
    if (bots === null) {
      getAllBots()
    }
  }, [bots, getAllBots])

  if (bots === null) {
    return "Loading bots..."
  }

  return (
    <>
      <Tab.Container defaultActiveKey="all">
        <div className="d-flex flex-wrap mb-2 align-items-center justify-content-between">
          <div className="mb-3 mr-3">
            <h6 className="fs-16 text-black font-w600 mb-0">
              {bots == null ? 0 : bots.length} Total Bots
            </h6>
            <span className="fs-14">Available for chat</span>
          </div>
          <div className="event-tabs mb-3 mr-3">
            <Nav as="ul" className="nav nav-tabs" role="tablist">
              <Nav.Item className="nav-item">
                <Nav.Link
                  className="nav-link"
                  data-toggle="tab"
                  to="#all1"
                  role="tab"
                  eventKey="all"
                >
                  All
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link
                  className="nav-link"
                  data-toggle="tab"
                  to="#own1"
                  role="tab"
                  eventKey="own"
                >
                  Your Bots
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <Tab.Content>
              <div className="tab-content">
                <Tab.Pane eventKey="all">
                  <AllBotTable bots={bots} user={user} />
                </Tab.Pane>
                <Tab.Pane eventKey="own">
                  <OwnBotTable bots={bots} user={user} />
                </Tab.Pane>
              </div>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </>
  );
};

export default Event;
