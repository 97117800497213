import React, { Fragment, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
//** Import Image */
import profile from "../../../../images/profile/profile.png";
import PageTitle from "../../../layouts/PageTitle";

import FirebaseContext from "../../../contexts/firebase/context"
import Credentials from "./Credentials"

import { dateDiffInDays } from "../../utils"


const getDeveloperStats = (bots, user) => {
  const stats = {
    totalBots: 0,
    activeBots: 0
  };
  bots.forEach(bot => {
    if (bot.developerUid == user.uid) {
      const botScore = bot.stats.score;
      stats.totalBots += 1;
      if (botScore > 0) {
        stats.activeBots += 1;
      }
    } 
  })

  return stats;
}

const AppProfile = () => {
  const [activeToggle, setActiveToggle] = useState("credential");
  const [sendMessage, setSendMessage] = useState(false);

  const { bots, getAllBots, user } = React.useContext(FirebaseContext);

  React.useEffect(() => {
    if (bots === null) {
      getAllBots()
    }
  }, [bots, getAllBots])

  const stats = React.useMemo(() => {
    if (bots !== null) {
      return getDeveloperStats(bots, user);
    }
    return {
      totalBots: 0,
      activeBots: 0
    };
  }, [bots])

  const creationDate = Date.parse(user.metadata.creationTime);
  const activeDays = dateDiffInDays(creationDate, new Date());

  return (
    <Fragment>
      <PageTitle  activeMenu="Profile" motherMenu="App" />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content">
                <div className="cover-photo"></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src={user.photoURL}
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">{user.displayName}</h4>
                    <p>Chai Developer</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">{user.metadata.creationTime}</h4>
                    <p>Joined on</p>
                  </div>
                  <Dropdown className="dropdown ml-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-user-circle text-primary mr-2" />
                        View profile
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-ban text-primary mr-2" />
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-4">
    			<div className="row">
    				<div className="col-lg-12">
    					<div className="card">
    						<div className="card-body">
    							<div className="profile-statistics">
    								<div className="text-center">
    									<div className="row">
    										<div className="col">
    											<h3 className="m-b-0">{activeDays}</h3><span>Days</span>
    										</div>
    										<div className="col">
    											<h3 className="m-b-0">{stats.totalBots}</h3> <span>Total Bots</span>
    										</div>
    										<div className="col">
    											<h3 className="m-b-0">{stats.activeBots}</h3> <span>Active Bots</span>
    										</div>
    									</div>
    								</div>
    							</div>
    						</div>
    					</div>
    				</div>
          </div>
        </div>

        <div className="col-xl-8">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <ul className="nav nav-tabs">
                    <li className="nav-item" onClick={() => setActiveToggle("credential")}>
                      <Link to="#credentials"  data-toggle="tab" className={`nav-link ${ activeToggle === "credential" ? "active show" : ""}`}>Credentials</Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div id="credentials" className={`tab-pane fade ${ activeToggle === "credential" ? "active show" : ""}`}>
                      <Credentials />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppProfile;
