export const getAllBots = async firebaseApp => {
  const snapshot = await firebaseApp.database().ref("bots").once("value")
  const bots = []
  const promises = []
  snapshot.forEach(botSnapshot => {
    promises.push(
      getBotFromSnapshot(botSnapshot, firebaseApp).then(bot => bots.push(bot))
    )
  })
  await Promise.all(promises)

  console.log("Fetched bots:")
  console.log(bots)
  return bots
}

const getBotFromSnapshot = async (snapshot, firebaseApp) => {
  const botUid = snapshot.key
  const botUser = await getUser(botUid, firebaseApp)
  const botDeveloper = await getUser(
    snapshot.child("developerUid").val(),
    firebaseApp
  )
  const bot = {
    botUid,
    ...snapshot.val(),
    user: botUser,
    developer: botDeveloper,
  }
  const conversations =
    bot.stats === undefined
      ? 0
      : bot.stats.conversations === undefined
      ? 0
      : bot.stats.conversations
  const messagesSent =
    bot.stats === undefined
      ? 0
      : bot.stats.messagesSent === undefined
      ? 0
      : bot.stats.messagesSent
  const score =
    bot.stats === undefined
      ? 0
      : bot.stats.score === undefined
      ? 0
      : bot.stats.score
  const qualityScore =
    bot.stats === undefined
      ? 0
      : bot.stats.median_nonzero_chat === undefined
      ? 0
      : bot.stats.median_nonzero_chat
  const engagement =
    bot.stats === undefined
      ? 0
      : bot.stats.engagement === undefined
      ? 0
      : bot.stats.engagement
  bot["score"] = score
  bot["qualityScore"] = qualityScore
  bot["engagement"] = engagement
  return bot
}

export const getUser = (uid, firebaseApp) => {
  return firebaseApp
    .database()
    .ref(`users/${uid}`)
    .once("value")
    .then(snapshot => snapshot.val())
}

export const getGlobalStats = firebaseApp => {
  return firebaseApp
    .database()
    .ref(`stats/global`)
    .once("value")
    .then(snapshot => snapshot.val())
}

export const getDeveloperKeys = (uid, firebaseApp) => {
  return firebaseApp
    .database()
    .ref(`botDevelopment/developers/${uid}`)
    .once("value")
    .then(snapshot => snapshot.val())
}

export const createUserIfNotExist = async (user, firebaseApp) => {
  const snapshot = await firebaseApp
    .database()
    .ref(`users/${user.uid}`)
    .once("value")
  if (!snapshot.exists()) {
    console.log(`Creating new user ${user.uid}`)
    return createUser(user, firebaseApp)
  } else {
    console.log(`User ${user.uid} exists, not re-creating.`)
  }
}

export const createUser = (user, firebaseApp) => {
  return firebaseApp.database().ref(`users/${user.uid}`).set({
    name: user.displayName,
    imageUrl: user.photoURL,
    color: generateRandomColor(),
  })
}

const generateRandomColor = () => {
  const randomFactor = 4294967296 // 16 ^ 8 for RRGGBBAA
  return Math.floor(Math.random() * randomFactor).toString(16)
}

const DEVELOPER_KEY_ENDPOINT =
  "https://europe-west2-chai-959f8.cloudfunctions.net/developer-key"
// export const getDeveloperKeys = async (user) => {
//   const token = await user.getIdToken(true)
//     const thing = await fetch(`${DEVELOPER_KEY_ENDPOINT}/${token}`,{method: "GET"})
// }

export const createDeveloperKey = async user => {
  const token = await user.getIdToken(true)
  const response = await fetch(`${DEVELOPER_KEY_ENDPOINT}?id_token=${token}`, {
    method: "PUT",
  })
  const json = await response.json()
  return json.key
}

export const deleteDeveloperKey = async (user, key) => {
  const token = await user.getIdToken(true)
  return fetch(`${DEVELOPER_KEY_ENDPOINT}?id_token=${token}&key=${key}`, {
    method: "DELETE",
  })
}

const INVITE_ENDPOINT =
  "https://europe-west2-chai-959f8.cloudfunctions.net/conversation-invite"

export const getInvite = async (user, invite) => {
  const token = await user.getIdToken(true)
  return fetch(`${INVITE_ENDPOINT}?id_token=${token}&invite=${invite}`, {
    method: "GET",
  })
}
