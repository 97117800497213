import React, { useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import Increase from "./acara/dashboardChart/Increase";

import FirebaseContext from "../../contexts/firebase/context"

import TotalUsage from "./acara/dashboardChart/TotalUsage";

const Usage = () => {
	const { firebaseApp } = React.useContext(FirebaseContext);

  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);

  const onUsageChange = (snapshot) => {
    const val = snapshot.val()
    if (val != null) {
      const now = new Date();
      const dd = String(now.getDate()).padStart(2, '0');
      const mm = String(now.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = now.getFullYear();

      const hh = String(now.getHours()).padStart(2, '0');

      const today = yyyy + mm + dd + '_' + hh;

      let filteredKeys = Object.keys(val).filter((key) => {
        return key.slice(0, 11) <= today;
      });

      setLabels(filteredKeys.map((key, index) => {
        let month = key.slice(4, 6);
        let day = key.slice(6, 8);
        let hour = key.slice(9);
        return `${month}/${day} ${hour}:00`;
      }));
      setData(filteredKeys.map((key, index) => {
        let usage = val[key];
        let totalUsage = 0;
        Object.values(usage).forEach((userUsage, index) => {
          totalUsage += userUsage.count;
        });
        return totalUsage;
      }));
    }
  }

  React.useEffect(() => {
    if (firebaseApp != null) {
      const ref = firebaseApp
        .database()
        .ref('dailyUsage/')
      ref.on("value", onUsageChange)
      return () => {
        ref.off("value", onUsageChange)
      }
    }
  }, [firebaseApp])

  let dailyUsage = {};
  labels.forEach((label, index) => {
    let usage = data[index];
    let day = label.slice(0, 5);
    if (dailyUsage[day] != null) {
      dailyUsage[day] += usage;
    } else {
      dailyUsage[day] = usage;
    }
  });

  let dailyLabels = Object.keys(dailyUsage);
  let dailyData = Object.values(dailyUsage);

	return (
	  <div className="col-xl-12">
      <Tab.Container defaultActiveKey="monthly">
        <div className="card" id="sales-revenue">
          <div className="card-header pb-0 d-block d-sm-flex border-0">
            <h3 className="fs-20 text-black mb-0">Total Usage</h3>
            <div className="card-action revenue-tabs mt-3 mt-sm-0">
              <Nav as="ul" className="nav nav-tabs" role="tablist">
                <Nav.Item className="nav-item">
                  <Nav.Link
                    className="nav-link"
                    data-toggle="tab"
                    to="#monthly"
                    role="tabpanel"
                    eventKey="monthly"
                  >
                    Last Month
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link
                    className="nav-link"
                    data-toggle="tab"
                    to="#weekly1"
                    role="tabpanel"
                    eventKey="weekly"
                  >
                    Last Week
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link
                    className="nav-link"
                    data-toggle="tab"
                    to="#today1"
                    role="tabpanel"
                    eventKey="today"
                  >
                    Today
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          <Tab.Content>
            <div className="card-body">
              <div className="tab-content">
                <Tab.Pane eventKey="monthly">
                  <div className="tab-pane fade show" role="tabpanel">
                    <TotalUsage labels={dailyLabels.slice(0, -1)} data={dailyData.slice(0, -1)} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="weekly">
                  <div className="tab-pane fade show" role="tabpanel">
                    <TotalUsage labels={dailyLabels.slice(-9, -1)} data={dailyData.slice(-9, -1)} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="today">
                  <div className="tab-pane fade show" role="tabpanel">
                    <TotalUsage labels={labels.slice(-26, -1)} data={data.slice(-26, -1)} />
                  </div>
                </Tab.Pane>
              </div>
            </div>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
	);
}

export default Usage;